import { Container, CustomTypography } from './styles';
import IconButton from '@mui/material/IconButton';
import MuiLink from '@mui/material/Link';
import { Link } from 'react-router-dom';
import Arrow1RightIcon from '~/components/icons/arrow1right.svg?react';
import Arrow1LeftIcon from '~/components/icons/arrow1left.svg?react';
import { Fragment } from 'react';
import { theme } from '~/theme';
import { useBreadcrumb } from '@ev/eva-container-api';

export interface BreadcrumbProps {
  compact: boolean;
}

export function Breadcrumbs({ compact }: BreadcrumbProps) {
  const {
    breadcrumb: { current, breadcrumbs },
  } = useBreadcrumb();

  if (!current || !breadcrumbs.length) {
    return null;
  }

  if (compact) {
    const href = breadcrumbs[breadcrumbs.length - 1]?.href;
    return (
      <IconButton component={Link} to={href}>
        <Arrow1LeftIcon />
      </IconButton>
    );
  }

  return (
    <Container>
      {breadcrumbs.map(({ label, href }) => (
        <Fragment key={label}>
          <MuiLink
            to={href}
            component={Link}
            variant="body2"
            color={theme.palette.text.primary}
            sx={{ cursor: 'pointer' }}
          >
            {label}
          </MuiLink>
          <Arrow1RightIcon />
        </Fragment>
      ))}
      {current && <CustomTypography variant="body2">{current}</CustomTypography>}
    </Container>
  );
}

import Paper from '@mui/material/Paper';
import { LogoContainer } from './styles';
import Logo from '~/components/icons/ev-logo.svg?react';
import Tabs from '@mui/material/Tabs';
import Tab, { TabProps } from '@mui/material/Tab';
import { useTranslation } from '~/util/i18next';
import { SvgIcon } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useUrls } from '~/util/useUrls';
import { useSidebarItems } from '~/components/sections/Sidebar/useSidebarItems';

interface LinkTabProps {
  label?: string;
  href: string;
  icon?: TabProps['icon'];
  value?: string;
}

function LinkTab({ href, ...restProps }: LinkTabProps) {
  return (
    <Tab
      component={Link}
      to={href}
      wrapped
      sx={{
        textTransform: 'none',
        padding: '12px 8px',
        minWidth: 'initial',
        fontSize: 11,
        hyphens: 'auto',
      }}
      {...restProps}
    />
  );
}

export const Sidebar = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const sidebarItems = useSidebarItems();

  const { toHome } = useUrls();

  const currentTab =
    sidebarItems.find(
      ({ href }) => (pathname.startsWith(href) && href !== '/') || (href === pathname && pathname === '/'),
    )?.label || false;

  return (
    <Paper
      variant="elevation"
      square
      elevation={0}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        position: 'sticky',
        top: 0,
        zIndex: 2,
        paddingInline: 1,
        maxHeight: '100vh',
        gridRowEnd: 'bottom',
        gridRowStart: 'top',
      }}
    >
      <Link to={toHome()}>
        <LogoContainer>
          <Logo width="72" height="50" />
        </LogoContainer>
      </Link>
      <Tabs
        value={currentTab}
        variant="scrollable"
        scrollButtons={false}
        orientation="vertical"
        sx={{ marginInline: `-8px`, mt: 2 }}
      >
        {sidebarItems.map(({ href, label, icon }) => (
          <LinkTab
            key={`${label}_${href}`}
            href={href}
            value={label}
            icon={<SvgIcon component={icon} />}
            label={t(label)}
          />
        ))}
      </Tabs>
    </Paper>
  );
};

import React from 'react';
import { useTranslation } from '~/util/i18next';
import { DropdownMenu } from '~/components/general/DropdownMenu';
import DowJonesLogo from '~/components/icons/dowjones-logo.svg?react';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import { aboutThisToolLink, featureRequestLink, imprintLink, knowledgeBaseLink } from '~/util/consts/links';
import { SupportLink } from './SupportLink';

export interface ISupportDropdownProps {
  anchorEl?: HTMLElement;
  onClose?: () => void;
}

export const SupportDropdown = ({ anchorEl, onClose }: ISupportDropdownProps) => {
  const { t } = useTranslation();

  return (
    <DropdownMenu title={t('general.support')} anchorEl={anchorEl} onClose={onClose}>
      <List sx={{ paddingBlock: 0.5 }} onClick={onClose}>
        <SupportLink link={aboutThisToolLink} translationKey="general.aboutkyc" />
        <SupportLink link={knowledgeBaseLink} translationKey="general.knowledgebase" />
        <SupportLink link={featureRequestLink} translationKey="general.featurerequest" />
        <SupportLink link={imprintLink} translationKey="general.imprint" />
      </List>
      <Box width={'100%'} maxHeight={40} textAlign={'center'}>
        <DowJonesLogo />
      </Box>
    </DropdownMenu>
  );
};

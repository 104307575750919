import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useState } from 'react';
import { Avatar } from '~/components/general/Avatar';
import LogoutIcon from '~/components/icons/logout.svg?react';

import useMediaQuery from '@mui/material/useMediaQuery';
import { theme } from '~/theme';
import { useTranslation } from '~/util/i18next';
import { ProfileInfo } from './ProfileInfo';
import ListItem from '@mui/material/ListItem';
import { signOut, useJwtToken } from '@ev/eva-container-api';

//TODO: Refactor and make 1 component to use on Language Switcher and here
export const ProfileMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const { jwtToken } = useJwtToken();
  const firstName = jwtToken.name.split(' ')[0] ?? '';
  const lastName = jwtToken.name.split(' ')[1] ?? '';
  const { t } = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return isDesktop ? (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: isDesktop ? 2 : 0 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar firstName={firstName} lastName={lastName} />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="profile-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,

          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            py: 2,
            px: 3,
            display: 'flex',
            gap: 2,
            '& .MuiAvatar-root': {
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
            ul: {
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <ListItem sx={{ pb: 3 }} divider disableGutters>
          <ProfileInfo firstName={firstName} lastName={lastName} />
        </ListItem>
        <MenuItem onClick={() => signOut()} disableGutters>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          {t('general.logout')}
        </MenuItem>
      </Menu>
    </>
  ) : (
    <ProfileInfo firstName={firstName} lastName={lastName} />
  );
};

import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/system';
import ErrorIcon from './error.svg?react';
import { theme } from '~/theme';
import Logo from '~/components/icons/ev-logo.svg?react';

export const LogoBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  justifyContent: 'center',
  background: theme.palette.background.default,
  height: theme.spacing(8),
  padding: theme.spacing(0, 2),
});

export const ErrorMessageBox = styled(Box)({
  marginX: 'auto',
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  flexGrow: 1,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
});

export const ErrorPage = ({
  title,
  message,
  buttonText,
  onClick,
}: {
  title: string;
  message: string;
  buttonText?: string;
  onClick?: () => void;
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        background: theme.palette.shade.grey5,
        minHeight: '100vh',
      }}
    >
      <LogoBox>
        <Logo />
      </LogoBox>
      <ErrorMessageBox>
        <Box
          sx={{
            marginX: 'auto',
            marginTop: theme.spacing(9.25),
          }}
        >
          <ErrorIcon width={theme.spacing(18.75)} color={theme.palette.error.main} />
        </Box>
        <Typography variant="h1" marginTop={theme.spacing(3)}>
          {title}
        </Typography>
        <Typography variant="body2" color={theme.palette.text.secondary} marginTop={theme.spacing(3)}>
          {message}
        </Typography>
        {onClick && (
          <Button
            variant="contained"
            sx={{
              width: 'fit-content',
              marginX: 'auto',
              marginTop: theme.spacing(6),
            }}
            onClick={onClick}
          >
            {buttonText}
          </Button>
        )}
      </ErrorMessageBox>
    </Box>
  );
};

import React from 'react';
import CheckIcon from '~/components/icons/check-naked.svg?react';
import { languages } from '~/util/consts/locale';
import { useTranslation } from '~/util/i18next';
import { DropdownMenu } from '~/components/general/DropdownMenu';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import i18next from 'i18next';
import { useSetLocale } from '~/util/useSetLocale';

export interface ILanguageDropdownProps {
  anchorEl?: HTMLElement;
  onClose: () => void;
}

export const LanguageDropdown = ({ anchorEl, onClose }: ILanguageDropdownProps) => {
  const { t } = useTranslation();
  const setLocale = useSetLocale();

  return (
    <DropdownMenu title={t('general.language')} anchorEl={anchorEl} onClose={onClose}>
      <List sx={{ paddingBlock: 0.5 }} onClick={onClose}>
        {languages.map(({ label, value }) => (
          <ListItemButton sx={{ display: 'flex' }} key={value} onClick={() => setLocale(value)}>
            <ListItemText primaryTypographyProps={{ variant: 'body2' }}>{t(label)}</ListItemText>
            {i18next.language === value && (
              <ListItemIcon>
                <CheckIcon />
              </ListItemIcon>
            )}
          </ListItemButton>
        ))}
      </List>
    </DropdownMenu>
  );
};

import PersonIcon from '~/components/icons/person.svg?react';
import { useMemo } from 'react';
import { useUrls } from '~/util/useUrls';
import { useTranslation } from '~/util/i18next';

export function useSidebarItems() {
  const { toHome } = useUrls();
  const { t } = useTranslation();

  return useMemo(
    () => [
      {
        href: toHome(),
        icon: PersonIcon,
        label: t('checklist.headline.KYC'),
      },
    ],
    [t, toHome],
  );
}

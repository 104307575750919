import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import GlobeIcon from '~/components/icons/globe.svg?react';
import { useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { theme } from '~/theme';
import { languages } from '~/util/consts/locale';
import { useTranslation } from '~/util/i18next';
import CheckIcon from '~/components/icons/check-naked.svg?react';
import i18next from 'i18next';
import { useSetLocale } from '~/util/useSetLocale';

export function LanguageSwitcher() {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const setLocale = useSetLocale();

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <GlobeIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            minWidth: 150,

            ul: {
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
            },
            li: {
              display: 'flex',
              justifyContent: 'space-between',
              px: 2,
            },
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: isDesktop ? 0 : 64,
              left: isDesktop ? 0 : 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
              marginLeft: 'auto',
              marginRight: 'auto',
            },
          },
        }}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      >
        {languages.map(({ label, value }) => (
          <MenuItem key={value} disableGutters onClick={() => setLocale(value)}>
            {t(label)}
            {i18next.language === value && <CheckIcon />}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

import Box from '@mui/material/Box';
import { Header } from '~/components/general/Header';
import MenuIcon from '@mui/icons-material/Menu';
import CrossIcon from '~/components/icons/cross-naked.svg?react';
import { useCallback, useState } from 'react';
import { LayoutMain } from '~/components/layouts/LayoutMain';
import { ProfileMenu } from '~/components/sections/ProfileMenu';
import { LanguageSwitcher } from '~/components/general/LanguageSwitcher';
import { MobileMenu } from '~/components/sections/MobileMenu';
import { theme } from '~/theme';
import useMediaQuery from '@mui/material/useMediaQuery';
import Logo from '~/components/icons/ev-logo.svg?react';
import { HelpSection } from '~/components/sections/HelpSection';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import { Breadcrumbs } from '~/components/general/Breadcrumbs';
import { Outlet } from 'react-router-dom';
import { FRAME_Z_INDEX } from '@ev/eva-container-api';
import { Sidebar } from '~/components/sections/Sidebar';

export default function KycFrame() {
  const [isMobileNavOpen, setMobileNavOpen] = useState<boolean>(false);

  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const handleMobileNavClick = useCallback(() => {
    setMobileNavOpen(!isMobileNavOpen);
  }, [isMobileNavOpen]);

  return (
    <LayoutMain
      header={
        <Box height="100%" maxHeight={68} paddingX={isDesktop ? 4 : 1}>
          <Header
            fullHeight={true}
            right={
              isDesktop && (
                <>
                  <LanguageSwitcher />
                  <HelpSection />
                  <ProfileMenu />
                </>
              )
            }
            center={!isDesktop && <Logo width="72" height="50" />}
            left={
              <>
                {!isDesktop && (
                  <>
                    <IconButton onClick={handleMobileNavClick}>
                      {!isMobileNavOpen ? <MenuIcon /> : <CrossIcon />}
                    </IconButton>
                    <Drawer
                      hideBackdrop
                      anchor="left"
                      sx={{ zIndex: FRAME_Z_INDEX - 1 }}
                      PaperProps={{
                        sx: {
                          paddingTop: `68px`,
                          width: '100%',
                          backgroundColor: theme.palette.backgroundExtension.grey,
                        },
                      }}
                      onClose={handleMobileNavClick}
                      open={isMobileNavOpen}
                    >
                      <MobileMenu
                        handleRedirect={() => {
                          setMobileNavOpen(false);
                        }}
                      />
                    </Drawer>
                  </>
                )}
                <Breadcrumbs compact={!isDesktop} />
              </>
            }
          />
        </Box>
      }
      aside={<Sidebar />}
    >
      <Outlet />
    </LayoutMain>
  );
}
